






.constructionPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: whitesmoke;
}

    .constructionLogo {
        height: 40vh;
        width: 50vw;
        filter: brightness(0)
                drop-shadow(0 0 15px rgb(231, 21, 21));    
        margin: 5vh 0;
    }

    .constructionGraf {
        font-size: 5vmin;
        width: 60vw;
        text-align: center;
    }


#errorLogo {

    width: max(40vw, 400px);
    margin: 2.5vw 0;
}