




#loaderWrapper {
    width: 50vmin;
    height: 50vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.loader {
    border: 6px solid white;
    border-top: 6px solid red;
    border-bottom: 6px solid red;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    margin-right: 1em;
    animation: loader 2s linear infinite;
  }
  
  @keyframes loader {
    0% {   transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }