









/*======RESET:======*/



html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}


/*=====NEW STYLING======*/




@font-face {
  font-family: 'BigShoulders';
  src: local('BigShouldersText-Black'), url('./fonts/Big_Shoulders_Text/static/BigShouldersText-Black.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'SixCaps';
  src: local('SixCaps-Regular'), url('./fonts/Six_Caps/SixCaps-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'BebasNeue';
  src: local('BebasNeue-Regular'), url('./fonts/Bebas_Neue/BebasNeue-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'BarlowCondensed';
  src: local('BarlowCondensed-Light'), url('./fonts/Barlow_Condensed/BarlowCondensed-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'BarlowCondensed-Bold';
  src: local('BarlowCondensed-Bold'), url('./fonts/Barlow_Condensed/BarlowCondensed-Bold.ttf') format('truetype');
  font-display: swap;
}







h1 {
  font-family: BigShoulders, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 7em;
  color: white;
  line-height: .85em;
  letter-spacing: -.025em;
  margin: 0 auto;
  width: fit-content;
  z-index: 6;
  position: relative;

}


h2 {
  font-family: BebasNeue, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 7.5vmin;
}

h3 {
  font-family: BebasNeue, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 5vmin;
}




h5 {
  font-family: BebasNeue, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 1.5em;
}

p {
  font-family: BarlowCondensed, Arial, Helvetica, sans-serif;
  font-size: 20px; /* Fallback for older browsers */
  font-size: clamp(20px, 3vmin, 30px); /* Modern browsers */
}

b {
  font-family: BarlowCondensed-Bold, Arial, Helvetica, sans-serif;
}

a {
  font-family: BarlowCondensed, Arial, Helvetica, sans-serif;
  color: black;
  cursor: pointer;
  text-decoration: none;
}




@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(45, 45, 45, 0.15); /* Charcoal with 80% opacity */  
}

  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .flexColumn {
    display: flex;
    flex-direction: column;
  
  }


  .flexRow {
      display: flex;
      /* border: 3px red ridge; */
  }


  .floatingNotification {
    position: fixed;
    z-index: 999;
    bottom: 5vmin;
    right: 5vmin;
    height: fit-content;
    width: fit-content;
  }

    .floatingNotification.fading {
      animation: 2s fadeOut;
    }


  #main {
    position: relative;
    background-color: white;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 2000px;
  }

  #footer {
    width: 100vw;
    height: 20em;
    min-height: 20vh;
    background: black;
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

   
        #footerLogo {
          width: min(300px, 80vw);
        }

   




        .formButton {
        
          background: rgba(14, 13, 13, 0.5);
          box-shadow:          2px    2px   5px 5px rgba(206,  58,  8,    .15), 
                       inset   5em   10em  10em      rgb(48,   44,  44,   .25),
                       inset 2.5em    5em  10em     rgba(235,  232, 230, 0.25),
                       inset  -1em   -2em  10em      rgb(46,   28,  28       );
          background-image: linear-gradient(rgba(42, 43, 44, .8), rgba(24, 6, 19, 0.9)),
                              url('./images/diamondplate.webp');
          color: white;
          padding: .25em 1em;
          margin: 5vh 0;
          /* font-size: 2em; */
          font-size: 20px; /* Fallback for older browsers */
          font-size: clamp(20px, 3vmin, 30px); /* Modern browsers */
          font-family: BebasNeue;
          transition: box-shadow .5s ease-in;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
  
              .formButton.adminButton {
                  margin: 1vmin 0 0 0;
              }
      
              .formButton.optionButton {
                  cursor: pointer;
                  font-family: BarlowCondensed;
                  text-align: left;
                  /* font-size: 1.8em; */
                  width: 100%
              }
  
              .formButton.backToButton {
                  font-family: BarlowCondensed;
                  /* font-size: 1.8em; */
                  margin: 7.5vh auto;
                  width: fit-content;
              }
  
              .formButton.submitButton {
                  background: red;
                  background-image: none;
                  box-shadow: inset .1em .1em .5em rgba(48,  44,  44,   .25);    
                  padding: .25EM .5em;
          
              }
  
                  .formButton.submitButton:hover {
                      box-shadow: inset .1em .1em 2.5em rgba(48,  44,  44,   .5);            
                  }
                  .formButton.submitButton:active {
                      box-shadow: inset .1em .1em 2.5em rgba(48,  44,  44,   .5);            
                  }
  
  
              .formButton:hover {
                  box-shadow:        2px    2px  10px 2px rgba(234, 7,   7,   0.5 ), 
                              inset  5em   10em  10em      rgba(48,  44,  44,   .25),
                              inset  2.5em  5em  10em     rgba(235, 232, 230, 0.25),
                              inset -1em   -2em  10em      rgb(46,  28,  28       );  
              }
              
              .formButton:active {
                  box-shadow:         2px   2px  10px 2px rgba(234, 7,   7,   0.75), 
                              inset   5em  10em  10em      rgb(48,  44,  44,   .25),
                              inset 2.5em   5em  10em     rgba(235, 232, 230, 0.25),
                              inset  -1em  -2em  10em      rgb(46,  28,  28       );  
              }


              .imdbIcon {
                height: 4vmin;
                margin: 0 2vmin 0 1vmin;
                z-index: 99;
            }


  @media only screen and (max-width: 660px) {

    .imdbIcon {
      height: 8vmin;
    }
  }

  @media (not (webp)) {
        
    .formButton {
        background-image: linear-gradient(rgba(42, 43, 44, .8), rgba(24, 6, 19, 0.9)),
                            url('./images/diamondplate.jpeg');
    }
}
