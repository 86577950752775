
  
  
  #header {
    height: 90vh;
    min-height: 600px;
    width: 100vw;
    background: black;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
  }

      #headerBackground {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
  
  
      #title {

        position: relative;
        width: min(600px, 80vw);
        margin: 0 auto;
        padding: 2.5%;
        padding: 2.5vw;
        background: rgba(14, 13, 13, 0.5);
        box-shadow:   2px 2px 5px 5px rgba(206, 58, 8, .15), 
                inset 5em 10em 10em  rgb(48, 44, 44, .25),
                inset 2.5em 5em 10em  rgba(235, 232, 230, 0.25),
                inset -1em -2em 10em  rgb(46, 28, 28);
        background-image: linear-gradient(rgba(42, 43, 44, .8), rgba(24, 6, 19, 0.9)),
                          url('../images/diamondplate.webp');
        background-size: 200%; /* Zoom in 2x */
        /* animation: 2s ease-in 0s 1 highFall; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        z-index: 99;
      }

    
  
        @keyframes highFall {
            0% {
              transform-origin: bottom left;
              transform:  rotate(-15deg) translatey(-400%) translatex(100%);
            }
            40% {
                transform: rotate(-15deg) translatey(0) translatex(0);
            }
            50% {
              transform: rotate(-12deg);
            }
            60% {
              transform: rotate(0);
            }
            70% {
              transform-origin: bottom right;
              transform:  rotate(10deg);    
            }
            75% {
              transform:  rotate(8deg);    
            }
            80% {
              transform: rotate(0);
            }
            85% {
              transform-origin: bottom left;
              transform: rotate(-2deg);
            }
            98% {
              transform: rotate(-1deg);
            }
            100% {
              transform: rotate(0);
            }
  
          
        }

        #flames {
          position: absolute;
          right: 0;
          top: 0vh;
          width: 100%;
          height: 0;
          animation: 3s ease-in 0s 1 burnDown;
        }
    
          @keyframes burnDown {
              0% {
                height: 7.5vh;
                top: -5vh;
              }            
              22% {
                height: 7.5vh;
              }
              
              33% {
                height: 5vh;
              }
              50% {
                height: 4vh
              }
             
              100% {
                height: 0;
              }
          }
  
  
   
      #headerLogo {
        width: 100%;
        background: rgba(0,0,0,.35);
      }
     
  
      #slogan {
        color: white;
        width: 100%;
        text-align: center;
        margin: 1em 0 .5em 0;
        /* animation: 3s ease-in 0s 1 fadeIn; */
      }

        .with-bullets span:not(:first-child)::before {
          content: "\2022";
          margin: 0 .5em 0 .5em;
        }


        @media (not (webp)) {
          #title {
            background-image: linear-gradient(rgba(42, 43, 44, .8), rgba(24, 6, 19, 0.9)),
                          url('../images/diamondplate.jpeg'); 
          }
        }